import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Commitees from "../components/Commitees";
import Navbar from "../components/Navbar";


function Home () {
    return(
        <>
        <Navbar/>
        <Hero />
        <Footer />
        </>
    )
}

export default Home;