import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import RegisterPage from "../components/RegisterCard";
import Hero from "../components/Hero";
 
import React from "react";
import Hero2 from "../components/Hero2";

function Register () {
    return(
        <>
        <Navbar/>
        <RegisterPage/>
        <Footer/>
        </>
    )
}

export default Register;
